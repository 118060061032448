import { useNavigate } from "react-router-dom";
import sushi1 from '../assets/images/sushi/sushi1.jpg'
import sushi2 from '../assets/images/sushi/sushi6.jpg'
import sushi9 from '../assets/images/sushi/sushi4.jpg'
import sushi11 from '../assets/images/sushi/sushi11.jpg'



const HomeBlog = () => {
  const navigate = useNavigate();

  return (
    <section className="content-inner overflow-hidden" style={{backgroundColor:'#EE4B2B'}}>
      <div className="container">
        <div className="row">
 
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp text-content">
            <h2 className=" text-center text-white">The Art and Tradition of Sushi</h2>
            <p className="text-white">
            Sushi, a quintessential element of Japanese cuisine, is a dish that combines vinegared rice with fresh fish, vegetables, and occasionally tropical fruits. Originating from ancient preservation methods in Southeast Asia, sushi evolved into an iconic culinary art form in Japan, renowned for its delicate flavors and exquisite presentation.

            </p>
            <a className="text-bold text-white" style={{fontSize: '22px', textDecoration:'underline'}}
              href="tel:+17195972422" 
             >+1(719)-597-2422</a>
    

            <div className="text-center d-flex">
            <button className="btn btn-primary btn-md shadow-primary m-r30  w-100" onClick={() => navigate('/order')}>Order Now</button>
              </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 image-grid">
            <img src={sushi1} />
            <img src={sushi2} />
            <img src={sushi9} />
            <img  src={sushi11} />
          
          </div>
        </div>
      </div>

 
    </section>
  );
};

export default HomeBlog;
