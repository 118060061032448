import { useNavigate } from "react-router-dom";
import sushi5 from '../assets/images/sushi/sushi5.jpg'
import sushi7 from '../assets/images/sushi/sushi9.jpg'


const OurEvent = () => {
  const navigate = useNavigate();

  return (
    <section className="content-inner overflow-hidden" style={{backgroundColor:'#EE4B2B'}} >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 image-grid">
            <img src={sushi5} alt="img" />
            <img src={sushi7} alt="img" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp text-content">
            <h2 className=" text-center ">LET US HELP WITH ​YOUR EVE​NTS</h2>
            <p className="text-black">
            Tomo Sushi provides catering services for events and large parties, ensuring that guests enjoy the same high-quality sushi in any setting, with customizable menus and professional service to accommodate various tastes and dietary needs.            </p>
            <a className="text-bold text-black" style={{fontSize: '22px', textDecoration:'underline'}}
              href="tel:+17195972422" 
             >(719)-597-2422</a>
            <br />
            <button className="btn btn-primary btn-md shadow-primary m-r30  w-100" onClick={() => navigate('/catering')}>Request Inqiure</button>
                        
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurEvent;
