import sushi9 from '../assets/images/sushi/sushi7.jpg'
const ContactSection = () => {
  return (
    <section className="contact-section d-flex h-100 bg-black">
      <div className="container">
        <div className="row h-100">
          <div className="col-lg-6 ">
            <img 
              src={sushi9} 
              alt="Restaurant Dish" 
              className="img-fluid w-100 h-100"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="col-lg-6 d-flex flex-column  p-5">
            <h2 className="title text-center text-white mb-4">SEE YOU SOON</h2>
            <p className="contact-info text-white">
              <strong>PHONE NUMBER</strong><br />
              (719)-597-2422

            </p>
            {/* <p className="contact-info text-white">
              <strong>EMAIL ADDRESS</strong><br />
              petalsofapeony65@gmail.com
            </p> */}
            <p className="contact-info text-white">
              <strong>RESTAURANT</strong><br />
              975 N Academy Blvd, Colorado Springs, CO 80909
       
            </p>
            <p className="mt-4 text-center text-orange ">
            Tomo Sushi offers a genuine taste of Japan with its expertly crafted sushi, prepared using traditional techniques and the freshest ingredients. The restaurant's serene ambiance and dedication to authenticity provide an unforgettable dining experience reminiscent of a true Japanese sushi bar.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
