import MainBanner2 from "../components/MainBanner2";
import Header from "../components/Header";
import Footer2 from "../components/Footer2";
import Home2OurBlog from '../components/Home2OurBlog'
import Gallery from "../components/Gallery";
import HomeBlog from "../components/HomeBlog";
import Home2Testimonial from "../components/Home2Testimonial";
import ContactSection from "../components/Contact";
import OurEvent from "../components/OurEvent";
import Home2OurMenu from "../components/Home2OurMenu";

const Home2 = ({restaurant}) => {

  return (
    <div className="page-content bg-white ">

        <Header />
      <MainBanner2 />
      <section className="content-inner-1 overflow-hidden pt-0">
        <div className="container">
          <div className="section-head menu-align mt-5">
            <h2 className="title mb-0 wow flipInX">Browse Menu</h2>
            <div className="pagination-align wow fadeInUp">
              <div className="menu-button-prev1 btn-prev rounded-xl btn-hover-2">
                <i className="fa-solid fa-arrow-left"></i>
              </div>
              <div className="menu-button-next1 btn-next rounded-xl btn-hover-2">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <Home2OurMenu restaurant={restaurant} prev={"menu-button-prev1"} next={"menu-button-next1"} />
      </section>      
        <HomeBlog />
      <Home2OurBlog /> 
        <OurEvent />
      <Home2Testimonial />
      <Gallery />
      <ContactSection />
      <Footer2 />
    </div>
  );
};

export default Home2;
