import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import video from '../assets/video/video3.mp4'
import poster2 from '../assets/images/vidposter2.png'

const Home2OurBlog = () => {
  const navigate = useNavigate();
  return (
    <section className="content-inner overflow-hidden bg-black">
      <div className="container">

        <div className="row">
        <div className="col-xl-6 col-lg-12 m-b30 wow fadeInUp">
            <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `url(${IMAGES.blog_large_pic1})` }}
            >
              <video autoPlay loop muted playsInline poster={poster2}>
                <source src={video} type="video/mp4"  />
              </video>
              <div className="dz-info">
                <h3 className="dz-title">
                  <Link to="/" className="text-white">
                    Exploring the World of Sushi
                  </Link>
                </h3>
                <div className="dz-meta">
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="section-head">
              <h2 className="title wow flipInX text-white">Our Restaurant</h2>
            </div>
            <div className="testimonial-2 " style={{backgroundColor:'black'}}>
            <div className="testimonial-detail">
              <div className="testimonial-text wow fadeInUp ">
                <p className="text-white">
                Welcome to Tomo Sushi, Colorado Springs' premier sushi destination! At Tomo Sushi, we specialize in crafting the freshest and most delicious sushi, from traditional favorites to innovative rolls. Our talented chefs also prepare hearty ramen and a variety of specialty dishes to satisfy every craving.

Our commitment to quality and flavor ensures that every bite is a delightful experience. Whether you're a sushi aficionado or new to the world of sushi, our menu caters to all preferences, making Tomo Sushi the perfect spot for everyone.

Convenience is key, and we are excited to offer online ordering through our website, making it easier than ever to enjoy our culinary creations from the comfort of your home. Join us at Tomo Sushi and discover why we are the top choice for sushi in Colorado Springs, CO. Experience exceptional food, warm hospitality, and an unforgettable dining experience at Tomo Sushi.
                           </p>
              </div>
              <div className="testimonial-info wow fadeInUp"> 
                <h5 className="testimonial-name text-white">Best Sushi In CO, Order Now!</h5>
                <span className="testimonial-position">Tomo Sushi</span>
                </div>
               
                <div
  onClick={() => navigate("/order")}
    className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
>
    <span>Order Online</span>
</div>
            </div>
          </div>
            
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
