import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useState } from "react";

const Home2OurMenu = ({ prev, next }) => {
  const [active, setActive] = useState();

  // Hard-coded restaurant data
  const restaurant = {
    Categories: [
      {
        id: 1,
        name: "Salads & Soups",
        Items: [
          {
            id: 1,
            name: "Avocado Salad",
            price: 8.00,
          },
          {
            id: 2,
            name: "Egg Drop Soup",
            price: 6.00,
          },
          {
            id: 3,
            name: "Kani Cucumber Salad",
            price: 10.00,
          },
          {
            id: 4,
            name: "Miso Soup",
            price: 3.00,
          },
        ],
      },
      {
        id: 2,
        name: "Vegetarian Rolls",
        Items: [
          {
            id: 5,
            name: "Asparagus Roll",
            price: 8.00,
          },
          {
            id: 6,
            name: "Avocado Roll",
            price: 7.00,
          },
          {
            id: 7,
            name: "Cucumber Avocado Roll",
            price: 8.00,
          },
          {
            id: 8,
            name: "Sweet Potato Roll",
            price: 8.00,
          },
        ],
      },
      {
        id: 3,
        name: "Nigiri / Sashimi",
        Items: [
          {
            id: 9,
            name: "Eel (Unagi)",
            price: 8.50,
          },
          {
            id: 10,
            name: "Salmon (Sake)",
            price: 8.00,
          },
          {
            id: 11,
            name: "Toro (Blue Fin Tuna)",
            price: 16.00,
          },
          {
            id: 12,
            name: "Yellowtail (Hamachi)",
            price: 8.00,
          },
        ],
      },
      {
        id: 4,
        name: "Sushi Combos",
        Items: [
          {
            id: 13,
            name: "Chirashi Bowl",
            price: 37.00,
          },
          {
            id: 14,
            name: "Insane Combo",
            price: 69.00,
          },
          {
            id: 15,
            name: "Tomo Boat",
            price: 85.00,
          },
        ],
      },
      {
        id: 5,
        name: "Chef Special Rolls",
        Items: [
          {
            id: 16,
            name: "Alaska Roll",
            price: 14.00,
          },
          {
            id: 17,
            name: "Hawaiian Roll",
            price: 20.00,
          },
          {
            id: 18,
            name: "Rainbow Roll",
            price: 16.00,
          },
          {
            id: 19,
            name: "Super Spider Roll",
            price: 21.00,
          },
        ],
      },
    ]
    
  };

  return (
    <div className="container">
      <Swiper
        className="swiper menu-swiper swiper-visible swiper-item-4"
        slidesPerView={4}
        spaceBetween={30}
        speed={1500}
        loop={true}
        modules={[Autoplay, Navigation]}
        autoplay={{ delay: 1500 }}
        navigation={{
          prevEl: `.${prev}`,
          nextEl: `.${next}`,
        }}
        breakpoints={{
          1200: { slidesPerView: 4 },
          991: { slidesPerView: 3 },
          575: { slidesPerView: 2 },
          240: { slidesPerView: 1 },
        }}
      >
        {restaurant?.Categories?.map((category) =>
          category?.Items?.map((item) => (
            <SwiperSlide className="swiper-slide" key={item.id}>
              <div
                className={`dz-img-box style-4 box-hover ${
                  active === item.id ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setActive(item.id);
                }}
              >
                <div className="menu-detail">
                  <div className="dz-content">
                    <h6 className="title">
                      <Link to="/order">{item?.name}</Link>
                    </h6>
                  </div>
                </div>
                <div className="menu-footer">
                  <span>Regular Price</span>
                  <span className="price">${item?.price}</span>
                </div>
                <Link className="detail-btn" to="/order">
                  <i className="fa-solid fa-plus"></i>
                </Link>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default Home2OurMenu;
