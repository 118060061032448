import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import poster from './banner.png'
import smposter from './bannersmall.png'
import { useEffect, useState } from "react";

const MainBanner2 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen size and set isMobile accordingly
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Initial check
    checkScreenSize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <div
      className="main-bnr-three newbox overflow-hidden"
      style={{

      }}
    >
      <div
        className="main-banner-new"
        style={{            height: "80vh" ,
      }}
      >
        <img
          src={isMobile ? smposter : poster}
          alt="poster"
          style={{
            objectFit: "fill",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
        <Container 
          style={{ 
            position: "relative", 
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            padding: "1rem 0",
            alignItems: isMobile ? 'center' : 'flex-start'

          }}
        >
          <div className="banner-content text-center">
            {/* You can add any top content here if needed */}
          </div>
          <div className="banner-btn d-flex justify-content-center mb-5">
            <div
              onClick={() => navigate('/order')}
              className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
            >
              <span>Order Online</span>
            </div>
            
            <div
              onClick={() => navigate("/menu")}
              className="btn btn-warning  btn-md shadow-primary btn-hover-1"
            >
              <span>View Menu</span>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MainBanner2;
