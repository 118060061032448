import Swal from "sweetalert2";

export const isStoreOpen = () => {
  // Get the current date and time in the Mountain Time Zone (MST)
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "America/Denver", // Set the time zone to Mountain Time Zone
  });

  // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const currentDayOfWeek = new Date(currentDate).getDay();

  // Extract the current time in HH:mm format
  const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
    hour12: false, // Use 24-hour format
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/Denver", // Set the time zone to Mountain Time Zone
  });

  // Define the store's opening hours in Mountain Time
  const openingHours = [
    { day: 0, start: "11:00", end: "20:20" }, // Sunday
    { day: 1, start: "11:00", end: "21:20" }, // Monday
    { day: 2, start: "11:00", end: "21:20" }, // Tuesday
    { day: 3, start: "11:00", end: "21:20" }, // Wednesday
    { day: 4, start: "11:00", end: "21:20" }, // Thursday
    { day: 5, start: "11:00", end: "21:20" }, // Friday
    { day: 6, start: "11:00", end: "21:20" }, // Saturday
  ];

  const openingHour = openingHours.filter((hour) => hour.day === currentDayOfWeek);

  // If openingHour is undefined, it means the store is closed today
  if (openingHour.length === 0) {
    return false;
  }

  // Check if the current time is within any of the store's opening hours
  const isOpen = openingHour.some((hour) => {
    const startTime = new Date(`01/01/2022 ${hour.start}`);
    const endTime = new Date(`01/01/2022 ${hour.end}`);
    const currentTimeFormatted = new Date(`01/01/2022 ${currentTime}`);
    return currentTimeFormatted >= startTime && currentTimeFormatted <= endTime;
  });

  return isOpen;
};



  export const checkIfLunchTime = () => {
    // Get the current date and time in the Eastern Time Zone
    const currentDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Extract the current time in HH:mm format
    const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
        hour12: false, // Use 24-hour format
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Define the store's opening hours
    const openingHours = { start: "10:30", end: "15:00" };

    // Extract hours and minutes from current time
    const [currentHour, currentMinute] = currentTime.split(":").map(Number);

    // Extract hours and minutes from opening hours
    const [startHour, startMinute] = openingHours.start.split(":").map(Number);
    const [endHour, endMinute] = openingHours.end.split(":").map(Number);

    // Check if current time is within lunch hours
    const isLunchTime =
        (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) &&
        (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute));

    return isLunchTime;
};


export const showTimeError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please come back later.',
      text: 'Hours: Mon-Thur 10:30-8:30PM, Fri-Sat 11:30-9:30PM, Sun 11:30-8:30PM..',

    })
  }
