import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../context/AppContext";
import { Modal, Button } from "react-bootstrap";
import Header from "../components/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoryModal from "../elements/CategoryModal";

const MenuStyle4 = ({ restaurant }) => {
  const [active, setActive] = useState(0);
  const cardRef = useRef([]);
  const categoryRef = useRef([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { language } = useContext(Context);
  const [activeCategory, setActiveCategory] = useState(0);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const categoryListRef = useRef(null);

  const getCategoryOffset = (categoryId) => {
    const categoryElement = document.getElementById(`category-${categoryId}`);
    if (categoryElement) {
      const rect = categoryElement.getBoundingClientRect();
      return rect.top + window.scrollY - 200;
    }
    return 0;
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    const categoryOffset = getCategoryOffset(categoryId + 1);
    window.scrollTo({ top: categoryOffset, behavior: 'smooth' });
    setCategoryModalVisible(false);

    // Scroll the category list to make the clicked category fully visible
    const activeCategoryElement = document.querySelector(`.category-item:nth-child(${categoryId + 1})`);
    if (activeCategoryElement && categoryListRef.current) {
      const elementLeft = activeCategoryElement.offsetLeft;
      const containerWidth = categoryListRef.current.clientWidth;
      const elementWidth = activeCategoryElement.clientWidth;
      
      const centerPosition = elementLeft - (containerWidth / 2) + (elementWidth / 2);
      categoryListRef.current.scrollTo({
        left: centerPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollToActiveCategory = () => {
    const activeCategoryElement = document.querySelector(".category-item.active");
    if (activeCategoryElement && categoryListRef.current) {
      const containerScrollLeft = categoryListRef.current.scrollLeft;
      const containerWidth = categoryListRef.current.clientWidth;
      const elementLeft = activeCategoryElement.offsetLeft;
      const elementWidth = activeCategoryElement.clientWidth;
      
      // Adjust the scroll position to make the active category fully visible
      if (elementLeft < containerScrollLeft) {
        // Scroll left to ensure the active category is fully visible
        categoryListRef.current.scrollTo({
          left: elementLeft - 100,
          behavior: "smooth",
        });
      } else if (elementLeft + elementWidth > containerScrollLeft + containerWidth) {
        // Scroll right to ensure the active category is fully visible
        categoryListRef.current.scrollTo({
          left: elementLeft + elementWidth - containerWidth + 100,
          behavior: "smooth",
        });
      } else {
        // Center the active category if it's not fully visible
        const centerPosition = elementLeft - (containerWidth / 2) + (elementWidth / 2);
        categoryListRef.current.scrollTo({
          left: centerPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const categoryElements = restaurant?.Categories.map((_, id) =>
        document.getElementById(`category-${id + 1}`)
      );

      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let activeCategory = 0;

      categoryElements.forEach((element, index) => {
        if (element) {
          const rect = element.getBoundingClientRect();
          const offsetTop = rect.top + window.scrollY;

          if (scrollPosition >= offsetTop) {
            activeCategory = index;
          }
        }
      });

      setActiveCategory(activeCategory);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [restaurant]);

  useEffect(() => {
    scrollToActiveCategory();
  }, [activeCategory]);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  return (
    <>
      <Header />
      <div className=" page-content bg-white" style={{ marginTop: '100px' }}>
      <div className="category-menu-wrapper">
            
            <div className="category-container1 d-flex align-items-center " style={{marginLeft:'20px', marginRight:'20px'}}>
              
              <i className="fa fa-bars category-icon" onClick={() => setCategoryModalVisible(true)}></i>
              <div className="category-list-wrapper" ref={categoryListRef}>
                <div className="category-list">
                  {restaurant?.Categories.map((category, index) => (
                    <span
                      key={category.id}
                      className={`category-item ${activeCategory === index ? 'active' : ''}`}
                      onClick={() => handleCategoryClick(index)}
                    >
                      {category.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            </div>
        <div className="container-fluid">
          {restaurant?.Categories &&
            restaurant.Categories.map(({ name, Items }, id) => {
              const sortedItems = [...Items].sort((a, b) => (b.image_url ? 1 : -1) - (a.image_url ? 1 : -1));
              return (
                <div key={id} id={`category-${id + 1}`} className="">
                  <h2>{name}</h2>
                  <div className="row" style={{ overflowX: "auto" }}>
                    {sortedItems.map(
                      ({ id, name, description, price, image_url, chinese_name, Restaurant_Item_Attributes }, ind) => (
                        <div
                          className="col-lg-3 col-md-4 col-6 m-b10 px-1"
                          onClick={() =>
                            handleCardClick({
                              id,
                              name,
                              description,
                              price,
                              image_url,
                              Restaurant_Item_Attributes,
                              chinese_name,
                            })
                          }
                          key={id}
                          ref={(node) => {
                            if (node) {
                              cardRef.current.push(node);
                            }
                          }}
                        >
                          <div className="dz-img-box style-7">
                            {image_url && (
                              <div className="dz-media">
                                <img
                                  src={image_url}
                                  alt={name}
                                  style={{
                                    maxHeight: "200px",
                                    width: "100%",
                                    minHeight: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            )}
                            <div className="dz-content">
                              <h5 className="title">
                                <Link to="#">
                                  {language === "en"
                                    ? name && name.length > 43
                                      ? name.slice(0, 43) + "..."
                                      : name
                                    : chinese_name}
                                </Link>
                              </h5>
                              <span className="price">${price}</span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <Modal
          show={modalVisible}
          onHide={() => setModalVisible(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {language === "en" ? selectedItem?.name : selectedItem?.chinese_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <div className="mr-5 hover-zoom">
                {selectedItem?.image_url && (
                  <img
                    src={selectedItem?.image_url}
                    alt={
                      language === "en"
                        ? selectedItem?.name
                        : selectedItem?.chinese_name
                    }
                    className="img-fluid"
                    style={{
                      maxHeight: "350px",
                      minHeight: "350px",
                    }}
                  />
                )}
              </div>
              <div className="m-2">
                {selectedItem?.description &&
                  selectedItem?.description !== "null" && (
                    <p style={{ fontWeight: "bold" }}>
                      Description: {selectedItem?.description}
                    </p>
                  )}
                <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Price: {selectedItem?.price}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <CategoryModal show={categoryModalVisible} onClose={() => setCategoryModalVisible(false)}>
          <div className="category-modal-content">
            {restaurant?.Categories.map((category, index) => (
              <div
                key={category.id}
                className={`category-modal-item ${activeCategory === index ? 'active' : ''}`}
                onClick={() => handleCategoryClick(index)}
              >
                {category.name}
              </div>
            ))}
          </div>
        </CategoryModal>
      </div>
    </>
  );
};

export default MenuStyle4;
