import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { IMAGES } from "../constent/theme";


const Gallery = () => {
  // Extracting images from the restaurant data
  const images = [
    {
      img: IMAGES.sushi1,
    },
    {
      img: IMAGES.sushi2,
    },
    {
      img: IMAGES.sushi3,
    },
    {
      img: IMAGES.suhi4,
    },
    {
      img: IMAGES.sushi1,
    },
    {
      img: IMAGES.sushi2,
    },
    {
      img: IMAGES.sushi3,
    },
    {
      img: IMAGES.suhi4,
    },
    
    
  ]

  return (
    <div className="container">
      {/* <Swiper
        className="swiper team-swiper swiper-visible swiper-btn-lr swiper-item-4"
        slidesPerView={4}
        spaceBetween={30}
        loop={true}
        speed={1200}
        modules={[Autoplay, Navigation]}
        navigation={{
          prevEl: ".team-button-prev",
          nextEl: ".team-button-next",
        }}
        autoplay={{
          delay: 1500,
        }}
        breakpoints={{
          1200: { slidesPerView: 4 },
          991: { slidesPerView: 3 },
          575: { slidesPerView: 2 },
          240: { slidesPerView: 1 },
        }}
      >
        {images?.map((img, ind) => (
          <SwiperSlide className="swiper-slide" key={ind}>
            <div className="dz-team style-1 wow fadeInUp">
              <div className="dz-media">
              <img
  src={img.img} // Access the correct property of the image object
  alt={`Image ${ind + 1}`}
  className="gallery-image img-fluid"
/>

              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="pagination mt-xl-0 m-t40">
          <div className="team-button-prev btn-prev-long">
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <div className="team-button-next btn-next-long">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </Swiper> */}
    </div>
  );
};

export default Gallery;
